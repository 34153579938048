//url utils

export function getLang() {
    let lang = "";
    const { origin, pathname } = window.location;

    if (origin.includes("localhost") || origin.includes("adobeaemcloud")) {
        lang = pathname.split("/")[4];
    } else {
        lang = pathname.replace(/^\/([^\/]*).*$/, "$1");
    }
    return lang || "de";
}
export function getBrand() {
    let brand = "";
    const { hostname, pathname } = window.location;
    if (hostname.includes("decide-now")) {
        brand = "MARLBORO";
    } else if (hostname.includes("upformore")) {
        brand = "LM";
    } else if (hostname.includes("teamfriendship")) {
        brand = "F6";
    } else {
        brand = pathname.split("/")[5];
    }
    return brand;
}
export function redirect(page) {
    const lang = getLang();
    const { origin, pathname } = window.location;
    if (origin.includes("localhost") || origin.includes("adobeaemcloud")) {
        window.location.pathname = pathname.split("/").slice(0, -1).concat(`${page}.html`).join("/");
    } else {
        window.location = `/${lang}/${page}`;
    }
}
