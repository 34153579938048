import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { getCookie } from "../../utils/storageUtils";

export default Justscan = {
    // create a loader to indicate background operations
    loader() {
        return `<div class="loader-wrapper"><div class="loader"></div></div>`;
    },
    /**
     * This method is responsible for the justscan rendering and response handeling when the justscan journey is completed.
     * @param {*} eventType type of justscan event
     * @param {*} eventName name of the justscan event
     * @param {*} baseUrl base url of the justscan widget
     * @param {*} campaignCode campaign code (unique id) of the justscan widget
     * @param {*} language language of the widget
     * @param {*} containerID id of the container in the website, inside which the widget will be rendered
     * @param {*} tdTrackingName tracking name for the widget to be used in CDP
     * @param {*} tdTrackingId tracking id(quiz id) for the widget (to identify in CDP)
     */
    renderJustscan(eventType, eventName, baseUrl, campaignCode, language, containerID, tdTrackingName, tdTrackingId) {
        const userID = uuidv4();

        const jRenderContainer = document.createElement("div");
        jRenderContainer.classList.add("scanpack-scenario");
        jRenderContainer.id = containerID;

        const closejustscan = document.createElement("button");
        closejustscan.textContent = "schließen";
        closejustscan.classList.add("close-justscan");
        jRenderContainer.appendChild(closejustscan);

        // const justscanHomeContainer = document.querySelector(".Homepage");
        document.body.appendChild(jRenderContainer);
        closejustscan.addEventListener("click", () => {
            jRenderContainer.remove();
        });

        window.scanpackInit.push({
            container: document.querySelector(`#${containerID}`),
            campaignCode: campaignCode,
            language: language,
            baseUrl: baseUrl,
            userId: userID,
            onScenarioLoaded: () => {
                console.warn("loaded");
                document.querySelector(".scanpack-scenario").classList.remove("loading");
            },
            onScenarioEnded: () => {
                console.log("ended");
                jRenderContainer.remove();
            },
            onNoScenarioAvailable: () => console.log("not available"),
            // trackEvent: (e) => console.log('event', e),
            // handles the response on completion of the widget. i.e. subsequent api calls, closure of the widget, etc.
            onMessage: (res) => {
                // CDP call to track the record.
                const brand = sessionStorage.getItem("regBrand");

                if (brand !== "MARLBORO" && res?.event.toLowerCase() === "completed") {
                    this.insertQuizData(eventType, eventName, tdTrackingId, tdTrackingName, language, res?.event);
                    if (brand === "F6" || brand === "LM") {
                        jRenderContainer.remove();
                    }
                }
            },
        });
    },
    /**
     * Initialization of the justscan widget by capturing the widget metadata from the content fragment, the start of
     * the widget is handled here, i.e. when user clicks on start button, this method first checks if the completion status for
     * the widget is already recorded in cdp, show the completion popup in case it is already completed, in case not, this
     * method calls the renderJustscan() method with the widget details to render the widget on the website.
     */
    initializeJustScan() {
        window.scanpackInit = window.scanpackInit || [];
        const justscanCF = document.querySelector("#justscanCF");
        if (justscanCF) {
            const eventType = justscanCF.querySelector(".cmp-contentfragment__element--justscanEventType").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();
            const eventName = justscanCF.querySelector(".cmp-contentfragment__element--justscanEventName").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();
            const baseUrl = justscanCF.querySelector(".cmp-contentfragment__element--justscanBaseUrl").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();
            const campaignCode = justscanCF.querySelector(".cmp-contentfragment__element--justscanCampaignCode").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();
            const language = justscanCF.querySelector(".cmp-contentfragment__element--justscanLanguage").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();
            const containerID = justscanCF.querySelector(".cmp-contentfragment__element--justscanContainerId").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();
            const tdTrackingName = justscanCF.querySelector(".cmp-contentfragment__element--tdTrackingName").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();
            const tdTrackingId = justscanCF.querySelector(".cmp-contentfragment__element--tdTrackingId").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();
            const tdToken = justscanCF.querySelector(".cmp-contentfragment__element--tdToken").querySelector(".cmp-contentfragment__element-value").innerHTML.trim();

            const justscanStartBtns = document.querySelectorAll(".btn-quiz");
            justscanStartBtns.forEach((justscanStart) => {
                justscanStart.addEventListener("click", () => {
                    document.body.insertAdjacentHTML("beforeend", Justscan.loader());
                    const segmentsURL = `https://cdp-eu01.in.treasuredata.com/cdp/lookup/collect/segments?version=2&token=${tdToken}&key.identity_identifier=${getCookie("userId")}_DE`;
                    axios
                        .get(segmentsURL)
                        .then((response) => {
                            const attemptedIds = response?.data[0]?.values;
                            const currentId = tdTrackingId.split("_");
                            if (attemptedIds.some((id) => currentId.includes(id))) {
                                const quizPopup = document.querySelector("#quizPopup");
                                quizPopup.style.display = "block";
                            } else {
                                this.renderJustscan(eventType, eventName, baseUrl, campaignCode, language, containerID, tdTrackingName, tdTrackingId);
                                const brand = sessionStorage.getItem("regBrand");
                                const btnId = justscanStart.querySelector("button").id;
                                brand === "MARLBORO" && this.insertQuizData(eventType, eventName, tdTrackingId, tdTrackingName, language, btnId);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => {
                            const loader = document.querySelector(".loader-wrapper");
                            if (loader) {
                                loader.remove();
                            }
                        });
                });
            });
        }
    },
    /**
     * this mehtod formats the CDP payload for each event on completion and calls the cdp insert event to push the
     * user data to CDP
     * @param {*} eventType type of the justscan event
     * @param {*} eventName name of the justscan event
     * @param {*} tdTrackingId tracking id(quiz id) to be used in CDP to track the completion of the event
     * @param {*} tdTrackingName tracking name for the event to be used in CDP
     * @param {*} language language of the event
     * @param {*} answer response/ answer of the user
     */
    insertQuizData(eventType, eventName, tdTrackingId, tdTrackingName, language, answer) {
        const justscanPayload = {
            quiz_id: tdTrackingId,
            quiz_name: tdTrackingName,
            quiz_language: language,
            quiz_qa: [
                {
                    question_id: eventType || "",
                    question: eventName || "",
                    answer_id: "",
                    answer: answer,
                },
            ],
            fl_success: 1,
            identity_identifier: `${getCookie("userId")}_DE`,
            home_country: "DE",
        };
        window.tdQuizSubmitEvent(justscanPayload);
    },
    init() {
        this.initializeJustScan();
    },
};
