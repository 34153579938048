// storage utils

export function setSessionStorage(key, value) {
    try {
        sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error("problem updating session storage: ", error);
    }
}
export function getSessionStorage(key) {
    const value = sessionStorage.getItem(key);
    return value && value !== "undefined" ? JSON.parse(value) : "";
}
export function removeSessionStorage(key){
    sessionStorage.removeItem(key);
}

export function setLocalStorage(key, value) {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error("problem updating local storage: ", error);
    }
}
export function getLocalStorage(key) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
}
export function removeLocalStorage(key){
    localStorage.removeItem(key);
}

export function setCookie(name, value, minutes) {
    const encodedValue = btoa(value);
    let expires = '';
    if (minutes) {
      const date = new Date();
      date.setTime(date.getTime() + minutes * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + encodedValue + expires + '; path=/; Secure';
  }
  
  export function getCookie(name) {
    const decodedValue =
      decodeURIComponent(
        atob(
          document.cookie.replace(
            new RegExp(
              '(?:(?:^|.*;)\\s*' +
                encodeURIComponent(name).replace(/[-.+*]/g, '\\$&') +
                '\\s*\\=\\s*([^;]*).*$)|^.*$'
            ),
            '$1'
          )
        )
      ) || null;
    return decodedValue;
  }
  
  export function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  
  export function deleteCookies(cookies) {
    if (!Array.isArray(cookies)) {
      console.error('Parameter should be an array of cookie names.');
      return;
    }
  
    cookies.forEach((cookieName) => {
      document.cookie =
        cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    });
  }