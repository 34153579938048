import { getSessionStorage } from '../../utils/storageUtils';
import { getBrand } from '../../utils/urlUtils';

const Home = {
    initializeHome() {
        let retailerCode = getSessionStorage('retailerCodeInfo');
        const registrationBrand = getBrand();

        const retailerCodesCF = document.querySelector('#retailerCodeCf');
        const allowedCodes =
            retailerCodesCF &&
            retailerCodesCF
                .querySelector('.cmp-contentfragment__element--retailerCodeHolder')
                .querySelector('.cmp-contentfragment__element-value')
                .querySelector('div')
                .querySelector('div')
                .innerHTML.trim();
        const isCodeAllowed = allowedCodes && allowedCodes.split(',').some((code) => code.trim() === retailerCode);
        let showPopup = registrationBrand === 'LM' && !isCodeAllowed ? false : true;
        showPopup && this.showRetailerPopup(retailerCode);
        this.hideRetailerPopup();
    },
    showRetailerPopup(retailerCode) {
        const { pathname } = window.location;

        if (
            pathname.includes('/home') &&
            getSessionStorage('thankYouErrorCode') === 0 &&
            getSessionStorage('retailerCodeInfo') &&
            getSessionStorage('channelRefCode') !== 'E_RED'
        ) {
            if (document.querySelector('.overlay-container')) {
                document.querySelector('.overlay-container').style.display = 'block';
            }
            if (document.getElementById('home-page-overlay')) {
                let handlerCodeContainer = document.querySelector('#dealercode-and-timestamp p');

                if (handlerCodeContainer && retailerCode) {
                    handlerCodeContainer.innerHTML += `<b>${retailerCode}</b>`;
                }
                let getTimestamp = setInterval(function () {
                    if (getSessionStorage("verifiedTimestamp")) {
                        let timestampContainer = document.createElement("p");
                        let today = new Date(parseInt(getSessionStorage("verifiedTimestamp"), 10));
                        let year = today.getFullYear();
                        let month = today.getMonth() + 1;
                        let date = today.getDate();

                        if (date < 10) date = '0' + date;
                        if (month < 10) month = '0' + month;

                        let minutes = today.getMinutes();
                        let hour = today.getHours();
                        if (minutes <= 9) {
                            minutes = '0' + minutes;
                        }
                        if (hour <= 9) {
                            hour = '0' + hour;
                        }
                        let formattedDate = `(${date}.${month}.${year}, ${hour} :${minutes})`;

                        timestampContainer.className = 'home-page-timestamp';
                        timestampContainer.innerText = formattedDate;
                        handlerCodeContainer.parentNode.appendChild(timestampContainer);
                        clearInterval(getTimestamp);
                    }
                }, 2000);
            }
        }
    },
    hideRetailerPopup() {
        const retailerPopupCloseBtn = document.querySelector('#home-page-overlay-button');
        retailerPopupCloseBtn &&
            retailerPopupCloseBtn.addEventListener('click', () => {
                if (document.querySelector('.overlay-container')) {
                    document.querySelector('.overlay-container').style.display = 'none';
                }
            });
    },
    init() {
        this.initializeHome();
    },
};

export default Home;