import Gigya from "../gigya/gigya";

const Navigation = {
    initializeNavigation() {
        const logoutBtn = document.querySelector(".logout");
        logoutBtn && logoutBtn.addEventListener("click", function () {
            Gigya.handleLogout();
        });
    },
    init() {
        this.initializeNavigation();
    },
};

export default Navigation;
